import request from '@/utils/request'

// 查询社区公约商务合作列表
export function listConfigPact(query) {
  return request({
    url: '/config/config/pact/list',
    method: 'get',
    params: query
  })
}

// 查询社区公约商务合作详细
export function getConfigPact(id) {
  return request({
    url: '/config/config/pact/' + id,
    method: 'get'
  })
}

// 新增社区公约商务合作
export function addConfigPact(data) {
  return request({
    url: '/config/config/pact',
    method: 'post',
    data: data
  })
}

// 修改社区公约商务合作
export function updateConfigPact(data) {
  return request({
    url: '/config/config/pact',
    method: 'put',
    data: data
  })
}

// 删除社区公约商务合作
export function delConfigPact(id) {
  return request({
    url: '/config/config/pact/' + id,
    method: 'delete'
  })
}
